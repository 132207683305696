<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center">
        <div class="text-h5 font-weight-medium">Dados de Titular</div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Dados de Titular"
          message="Preciso de ajuda para cadastrar dados de titular."
        >
          <p>
            Os Dados do Titular se referem à listagem das informações do titular que serão utilizadas para o tratamento de dados.
          </p>
          <p>
            Os exemplos de dados de Titular descritos na lei são Dados Pessoais e Sensíveis.
            Apesar disso, <b>outros dados</b> não explícitos na lei também podem ser utilizados.
            Por exemplo, tratamento de dados como o lucro da empresa não são diretamente pessoais, mas podem ser alvo de eventuais processos jurídicos.
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Dados Pessoais (LGPD Art. 5, inciso I)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  I - dado pessoal: informação relacionada a pessoa natural identificada ou identificável;
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Dados Pessoais Sensíveis (LGPD Art. 5, inciso II)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  II - dado pessoal sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openBearerInfoDialog()">
          Novo Dado de Titular
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="bearerInfos"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, aguarde"
        hide-default-footer
        :items-length="1"
        no-data-text="Nenhum dado de Titular"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openBearerInfoDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn icon @click="deleteDialog = true; bearerInfo_id = item.id">
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog v-model="bearerInfoDialog" max-width="800" :persistent="loadingSave">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="bearerInfoForm.id">
            Edição de Dado de Titular
          </div>
          <div v-else>
            Novo Dado de Titular
          </div>
          <div>
            <v-btn icon :disabled="loadingSave" @click="bearerInfoDialog = false">
              <v-icon color="black" >mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
        <v-form ref="form" @submit.prevent="saveBearerInfo()">
          <v-text-field
            v-model="bearerInfoForm.title"
            label="Título *"
            outlined
            hide-details="auto"
            :disabled="loadingSave"
            :rules="[$rules.required]"
          />
          <v-textarea
            v-model="bearerInfoForm.description"
            label="Descrição *"
            outlined
            hide-details="auto"
            color="black"
            class="my-3"
            :disabled="loadingSave"
          :rules="[$rules.required]"
          />
        <v-card-actions class="pt-3 pb-0 pr-0">
          <v-spacer />
          
          <v-btn color="success" type="submit"  :loading="loadingSave">
            Salvar
          </v-btn>
        </v-card-actions>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500" :persistent="loadingDelete">
      <v-card>
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Dado de Titular?  
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" class="mr-1" :disabled="loadingDelete" text @click="deleteDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="error" class="ml-1" :loading="loadingDelete" text @click="deleteBearerInfo()">
            Excluir
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "BearerInfo",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingDelete: false,
    loadingSave: false,
    bearerInfoDialog: false,
    editDialog: false,
    deleteDialog: false,
    bearerInfo_id: -1,
    bearerInfoForm: {
      title: "",
      description: "",
    },
    headers: [
      {
        text: "Título",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "title",
      },
      { text: "Descrição", value: "description", align: "start" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    bearerInfos: [],
  }),
  mounted() {
    this.getBearerInfo();
  },
  methods: {
    saveBearerInfo(){
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if(this.bearerInfoForm.id){
          request = this.$axios.put(`/bearer_info/${this.bearerInfoForm.id}`, this.bearerInfoForm)
        } else {
          request = this.$axios.post("/bearer_info", this.bearerInfoForm)
        }
        request
          .then((response) => {
            this.$showMessage("success", "Dado de Titular Salvo com sucesso");
            this.getBearerInfo();
            this.bearerInfoDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                this.$showMessage("error", "Dado de Titular não encontrado")
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro Inesperado")
          })
          .finally(() => {
            this.loadingSave = false
          })
      }
    },
    deleteBearerInfo() {
      this.loadingDelete = true;
      this.$axios.delete(`/bearer_info/${this.bearerInfo_id}`)
      .then((response) => {
        this.$showMessage("success", "Dado de Titular Excluido");
        this.getBearerInfo();
        this.deleteDialog = false
      })
      .catch((error) => {        
        if (error.response) {
          if (error.response.status == 404) {
            this.$showMessage("error", "Dado de Titular não encontrado")
            return;
          }
        }
        this.$showMessage("error", "Ocorreu um erro inesperado")
      })
      .finally(() => {
        this.loadingDelete = false
      })
    },
    getBearerInfo(){
      this.loading = true;
      this.$axios.get("/bearer_infos")
      .then((response) => {
        this.bearerInfos = response.data
      })
      .catch((error) => {
        this.$showMessage("error", "Ocorreu um erro inesperado")
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openBearerInfoDialog(bearerInfo) {
      if(bearerInfo){
        this.bearerInfoForm = Object.assign({}, bearerInfo)
      } else {
        this.bearerInfoForm = {}
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      }
      this.bearerInfoDialog = true;
    }
  }
};
</script>
